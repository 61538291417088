<template>

<b-container>
 <b-alert v-if="appState!='loading'"
            :show="alertComputed.alertVisible"
            :dismissible="alert.dismissible"
            :variant="alert.variant"
            @dismissed="hideAlert"
            @dismiss-count-down="countDownChanged">
        <p class="preserve-lb">{{alert.alertText}}</p>
        <b-progress v-show="alertComputed.alertProgressVisible"
                  :variant="alert.variant"
                  :max="alert.dismissSecs"
                  :value="alert.dismissCountDown"
                  height="4px">
        </b-progress>
    </b-alert>


  <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
  <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div> 
  <div v-else-if="appState=='ready'"> 
    <b-row> 
      <b-col>
          <h6>app version={{ VUE_APP_VERSION }}, buildtime={{ VUE_APP_BUILDTIME }}</h6>
      </b-col>
    </b-row>
    <b-row> 
      <b-col>
          <b-button v-if="$$$isLocal()" variant="danger" @click="mongoprod2local()">P-DBs-->Local-DBs (ttoodle-dev)</b-button>
          <b-button v-else variant="danger" @click="resettestdbs()">P-DBs-->T-DBs (!!!)</b-button>          
      </b-col>
    </b-row>
    <b-row> 
      <b-col>
          <b-button variant="danger" @click="backupdb()">Backup DB</b-button>
      </b-col>
     <b-col>
          <b-button variant="danger" @click="startscrape()">start scrape</b-button>
      </b-col>
      <b-col>
          <b-button variant="danger" @click="startscrapebetinfo()">start scrape betinfos</b-button>
      </b-col>

    </b-row>
    <b-row>
      <b-col>
            <h5 style="background:lightgrey;">stdout and stderr</h5>
            <b-form-textarea id="textarea2"
                    v-model="stdout"
                    size="sm"
                    placeholder=""
                    :rows="10"
                    >
            </b-form-textarea>
      </b-col>
    </b-row>
  </div>
</b-container>
  
</template>

<script>
import DBAPI from '../db/dbapi'
import { s2i, makeCopy } from '../helper/Utils'
import MessageAlertMixin from '../mixins/MessageAlertMixin'

export default {
  name: 'DBAdmin',
  mixins:[MessageAlertMixin],
  mounted() {
    this.processParams()
    this.processQuery()
    this.getData()

  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.hideAlert()
        this.processParams()
        this.processQuery()
      },
  },
  data () {
    return {
      appState:'loading',
      VUE_APP_VERSION: process.env.VUE_APP_VERSION,
      VUE_APP_BUILDTIME: process.env.VUE_APP_BUILDTIME,
      errorMessage:null,
      insertDbDisabled: false,
      stdout:''
    }
  },
  computed: {
    
  },
  methods: {
    processParams() {
      
      if (this.$route.params) {
      } else {
      }
    },

    processQuery() {
      if (this.$route.query) {
      }
    
    },
    getData: function() {
      this.appState = 'loading'
      var self = this
      
      var userData = this.$$$userData()
      let userId = userData.user_id
      if (!userId) {
        console.error("nicht angemeldet!")
        self.appState = 'error'
        return
      }

      self.appState = 'ready'
        
    },
    goBack: function() {
      this.$router.go(-1);
    },
    resettestdbs() {
      this.appState = 'loading'
      var self = this

      DBAPI.putResetTestDbs().then(function(response) {
          if (response.data.error){
            //app.errorMessage = response.data.message;
            console.error(response.data)
            self.appState = 'error'

					}	else {
            self.stdout = '----stdout----\n' + response.data.stdout + '----stderr----\n' +  response.data.stderr
            self.appState = 'ready'
                    
          }       
      })
      .catch(e => {
        console.error(e)
        self.appState = 'error'
      }); 
    },
    mongoprod2local() {
      this.appState = 'loading'
      var self = this

      DBAPI.putMongoProd2Local().then(function(response) {
          if (response.data.error){
            //app.errorMessage = response.data.message;
            console.error(response.data)
            self.appState = 'error'

					}	else {
            self.stdout = '----stdout----\n' + response.data.stdout + '----stderr----\n' +  response.data.stderr
            self.appState = 'ready'
                    
          }       
      })
      .catch(e => {
        console.error(e)
        self.appState = 'error'
      }); 
    },
    backupdb() {
      this.appState = 'loading'
      var self = this

      DBAPI.putBackupDb().then(function(response) {
          if (response.data.error){
            //app.errorMessage = response.data.message;
            console.error(response.data)
            self.appState = 'error'

					}	else {
            self.stdout = '----stdout----\n' + response.data.stdout + '----stderr----\n' +  response.data.stderr
            self.appState = 'ready'
                    
          }       
      })
      .catch(e => {
        console.error(e)
        self.appState = 'error'
      }); 
    },
    startscrape() {
      this.appState = 'loading'
      var self = this

      DBAPI.putStartScrape().then(function(response) {
          if (response.data.error){
            //app.errorMessage = response.data.message;
            console.error(response.data)
            self.appState = 'error'

					}	else {
            self.stdout = '----stdout----\n' + response.data.stdout + '----stderr----\n' +  response.data.stderr
            self.appState = 'ready'
                    
          }       
      })
      .catch(e => {
        console.error(e)
        self.appState = 'error'
      });
    },
    startscrapebetinfo() {
      this.appState = 'loading'
      var self = this

      DBAPI.putStartscrapebetinfo().then(function(response) {
          if (response.data.error){
            //app.errorMessage = response.data.message;
            console.error(response.data)
            self.appState = 'error'

					}	else {
            self.stdout = '----stdout----\n' + response.data.stdout + '----stderr----\n' +  response.data.stderr
            self.appState = 'ready'
                    
          }       
      })
      .catch(e => {
        console.error(e)
        self.appState = 'error'
      });
    },


    
  }
}

</script>

<style>

</style>
